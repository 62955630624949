import { useState, useEffect, createContext, useContext, useRef } from 'react'

import firebase from 'firebase/app';
import getConfig from 'next/config'

import 'firebase/auth'
import 'firebase/analytics'

import useTimeout from 'lib/hooks/useTimeout'
import { GET } from 'lib/request'


const defaultData = {
  user: null,
  hasUser: () => false,
  loginWithGoogle: () => {},
  loginWithFacebook: () => {},
  logout: () => {},
  loading: true,
  loggingIn: false,
  authError: null,
  firebase: {},
  sendVerification: () => {}
};

const wait = n => new Promise(r => setTimeout(r,n))

const AuthContext = createContext({ ...defaultData });
const useAuth = () => useContext(AuthContext);

const useUserAuth = () => {

  const [ user, setUser ] = useState()
  const [ authError, setAuthError ] = useState()
  const [ hasNextLink, setNextLink ] = useState(null)
  const [ needVerification, setVerification ] = useState()
  const [ noEmail, setNoEmail ] = useState()
  const [ loading, setLoading ] = useState( true )
  const [ loggingIn, setLoggingIn ] = useState( false )
  const app = useRef()
  const nextToLink = useRef()
  const lastCred = useRef()
  const tempUser = useRef()

  const getUserProperties = async () => {
    const resp = await GET('/me')
    return resp.data
  }

  const assignUser = async ( user ) => {
    setUser({
      ...user.toJSON(),
      ...(await getUserProperties())
    })
  }

  useEffect(() => {
    if(user) setTimeout(() => {
      setLoading(false)
    },500)
  },[ user ])

  const timeout = useTimeout()

  useEffect(() => {
    if(!process.browser) return undefined;

    //
    const { publicRuntimeConfig } = getConfig()
    firebase.current = firebase.apps.length ? firebase.app() :
      firebase.initializeApp(publicRuntimeConfig.firebase)

    // set local persistence local
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)

    // auth observer
    const auth = firebase.auth().onAuthStateChanged(async function(user) {
      // console.log('auth change', user)
      // initial change is always null

      if(!user) return false;


      if(!user.email){
        // wait for lastCred.current to resolve
        await wait(1000)

      }

      if(
        !user.email &&
        !(lastCred.current||{}).credential
      ){
        await logout()
        return false;
      }


      // facebook user have no email
      else if(!user.email && (lastCred.current||{}).credential){

        await user.delete()
        nextToLink.current = lastCred.current.credential
        setNextLink({ f: lastCred.current.credential, email: null })

      // email not verified
      }else if(!user.emailVerified){

        tempUser.current = user
        setVerification(user)

      // seems ok
      }else{

        lastCred.current = null

        if(nextToLink.current) {
          await user.linkWithCredential(nextToLink.current)
          await user.reload()
          nextToLink.current = null
          setNextLink(null)
        }

        assignUser(user)
      }

    });

    // wait for 5 seconds for new user
    const t = timeout(() => {
      if(firebase.current.auth.currentUser) return;
      if(loading) setLoading(false)
    },5000)

    return () => {
      t()
      auth()
    }

  },[ process.browser ])

  const hasUser = () => !!(user && user.uid)

  const loginWithGoogle = async () => {
    setAuthError(null)
    setLoggingIn(true)
    try{
      const provider = new firebase.auth.GoogleAuthProvider();
      lastCred.current = await firebase.auth().signInWithPopup(provider)
    }catch(e){

      if(e.code && e.code === 'auth/account-exists-with-different-credential'){
        nextToLink.current = e.credential
        setNextLink({ g: e.credential, email: e.email })
      }else{
        console.log(e)
        setAuthError(e)
      }
    }
    setLoggingIn(false)
  }

  const loginWithFacebook = async () => {
    setAuthError(null)
    setLoggingIn(true)
    try{
      const provider = new firebase.auth.FacebookAuthProvider();
      lastCred.current = await firebase.auth().signInWithPopup(provider)
    }catch(e){

      if(e.code && e.code === 'auth/account-exists-with-different-credential'){
        nextToLink.current = e.credential
        setNextLink({ f: e.credential, email: e.email })
      }else{
        console.log(e)
        setAuthError(e)
      }
    }
    setLoggingIn(false)
  }

  const logout = async () => {
    await firebase.current.auth().signOut()
    setUser(null)
  }

  const sendVerification = async () => {
    await tempUser.current.sendEmailVerification({
      url: window.location.href
    })
  }

  return {
    user,
    loading,
    hasUser,
    hasNextLink,
    needVerification,
    loginWithGoogle,
    loginWithFacebook,
    logout,
    firebase,
    loggingIn,
    authError,
    sendVerification,
  }

}


const AuthProvider = ({ children }) => {

  const data = useUserAuth();

  return (
    <AuthContext.Provider value={data}>{children}</AuthContext.Provider>
  );
};

export { AuthProvider, useAuth };
