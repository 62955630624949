
import axios from 'axios'
import Recipience from 'recipience'
import qs from 'qs'
import randomstring from 'randomstring'
import firebase from 'firebase/app'

const createAxios = async () => {
  const token = firebase.auth().currentUser ?
    await firebase.auth().currentUser.getIdToken() : null

  const instance = axios.create({
    baseURL: `/api`,
    withCredentials: true,
    headers: {
      'x-authorization': token
    }
  });

  return instance;
}

export const POST = async (url, data) => (await createAxios()).post(url, data);
export const PATCH = async (url, data) => (await createAxios()).patch(url, data);
export const PUT = async (url, data) => (await createAxios()).put(url, data);
export const DELETE = async (url, params) => (await createAxios()).delete(url, { params: params || { foo: 'bar' } });
export const GET = async (url, params) => (await createAxios()).get(url, { params });

export const UPLOAD = async (file, prefix, isImage) => {

  const extension = file.name.split('.').pop()
  // console.log({ file })
  // console.log({ blob, width, height })


  const progress = new Recipience()

  const urlResp = await GET('/upload',{
    name: file.preferredName ? `${file.preferredName}.${file.extension}` : file.name,
    prefix,
    mime: file.blobMime || file.type
  })

  const { uploadUrl, url:resultUrl } = urlResp.data


  const result = axios.put(uploadUrl, file.blob || file, {
    onUploadProgress: function(progressEvent) {
      progress.pipe(Math.round((progressEvent.loaded * 100) / progressEvent.total))
    },
    headers: {
      'Content-Type': file.blobMime || file.type,
      'Cache-Control': `max-age=${60 * 24 * 30}`,
    }
  }).then(resp => {

    progress.done()
    return {
      url: resultUrl
    };

  })

  return { progress, result }
}

const _wait = n => new Promise(r => setTimeout(r,n))


const _recurseObjAndUpload = async (obj, prefix) => {

  let queue = [];

  // find File object, and do upload
  for(let i in obj){

    // this is an File and not disabled
    if(obj[i] instanceof File && !obj[i].disabled) {

      let { progress, result } = await UPLOAD(obj[i], prefix, /^image\//.test(obj[i].type));

      // mutate original File, add `url` property
      result = result.then(res => {
        obj[i].url = res.url
        return res.url
      })

      queue.push({ progress, result })

    }

    // not an File, but is an object, recursively do this
    else if(obj[i] && typeof obj[i] === 'object') {

      queue = [ ...queue, ...(await _recurseObjAndUpload(obj[i], prefix)) ]

    }
  }

  return queue
}

export const uploadAsNeeded = async (obj, setUploadProgress) => {

  // upload any File object and add `url` into the object -> mutating
  // returns: { promise, progress }
  // - promise: a promise, will return all url
  // - progress: a recipience containing progress of all combined upload, in precentage
  // https://www.npmjs.com/package/recipience

  // as the name implies
  const queue = await _recurseObjAndUpload(obj)

  // collect all upload progress into a single percentage
  // andd feed it into setUploadProgress
  const numProgress = queue.length * 100
  let currentProgress = []
  let done = false

  numProgress && queue.forEach((v,i) => {
    currentProgress.push(0)

    v.progress.stream.each((num) => {
      currentProgress[i] = num
      !done && setUploadProgress && setUploadProgress(
        Math.round((currentProgress.reduce((a,b) => a+b,0)*100)/numProgress)
      )
    })

  })

  // a flag to mark all upload as done
  // also marks 100% on setUploadProgress
  const allDone = Promise.all(queue.map(v => v.result)).then(async v => {
    await _wait(200)
    done = true;
    setUploadProgress && setUploadProgress(100)
    return v
  })

  return allDone

}
