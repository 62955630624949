import { useRef } from 'react'

const useTimeout = () => {

  const t = useRef()
  const set = (fn, n) => {
    if(t.current) clearTimeout(t.current)
    const timeout = setTimeout(fn, n)
    t.current = timeout
    return () => clearTimeout(timeout)
  }

  return set

}


export default useTimeout
